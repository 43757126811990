import React from "react";
import Carousel from "react-material-ui-carousel";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

const aristotle = require("../../assets/quotes/aristotle.png");
const einstein = require("../../assets/quotes/einstein.png");
const miles_davis = require("../../assets/quotes/miles-davis.png");
const warren_buffet = require("../../assets/quotes/warren-buffet.png");

const quotes = [
    {
        whom: "MILES DAVIS",
        quote: "I always listen to what I can leave out.",
        image: miles_davis
    },
    {
        whom: "Albert Einstein",
        quote: "The most powerful force in the world is compound interest.",
        image: einstein
    },
    {
        whom: "Aristotle",
        quote: "The whole is greater than the sum of its parts.",
        image: aristotle
    },
    {
        whom: "Warren Buffet",
        quote: "Price is what you pay. Value is what you get.",
        image: warren_buffet
    },
];

const Quote = ({image, quote, whom}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box display="flex"
                     flexDirection="row"
                     justifyContent="center"
                     alignItems="center">
                    <img alt="Quote" src={image}/>
                </Box>
                <Box display="flex"
                     flexDirection="row"
                     justifyContent="center"
                     alignItems="center"
                     fontSize={30}
                     fontWeight={700}
                     textAlign="center"
                     color="#1f2774">
                    {quote}
                </Box>
                <Box display="flex"
                     flexDirection="row"
                     justifyContent="center"
                     alignItems="center"
                     fontSize={18}
                     fontWeight={700}
                     color="#ff3636"
                     style={{textTransform: "uppercase"}}>
                    {whom}
                </Box>
            </Grid>
        </Grid>
    )
}

const Quotes = () => {

    return (
        <Box display="flex"
             flexDirection="row"
             justifyContent="center"
             alignItems="center"
             alignSelf="center"
             height="100vh">
            <Box display="flex"
                 flexDirection="column"
                 justifyContent="center"
                 alignItems="center"
                 alignSelf="center"
                 width="50%">
                <Carousel
                    autoPlay={true}
                    animation="fade"
                    indicators={false}
                    navButtonsAlwaysInvisible={true}
                    timeout={500}>
                    {
                        quotes.map((item, i) => <Quote key={i} {...item}/>)
                    }
                </Carousel>
            </Box>
        </Box>
    )
}

export default Quotes;