import React from "react";
import {Field, Form, Formik} from "formik";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import * as Yup from "yup";
import {useRouter} from "next/router";

const LoginForm = ({initialValues, onSubmit}) => {
    const router = useRouter();

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string()
            .required('Password is required'),
    });

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}>
            {({}) => (
                <Form noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel>Email</InputLabel>
                            <Field name="email">
                                {({field, form}) => (
                                    <TextField
                                        id="email"
                                        type="text"
                                        fullWidth
                                        error={form.touched.email && Boolean(form.errors.email)}
                                        helperText={form.touched.email && form.errors.email}
                                        {...field}/>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Password</InputLabel>
                            <Field name="password">
                                {({field, form}) => (
                                    <TextField
                                        id="password"
                                        type="password"
                                        fullWidth
                                        error={form.touched.password && Boolean(form.errors.password)}
                                        helperText={form.touched.password && form.errors.password}
                                        {...field}/>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary">
                                Sign in
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            Forgot password? {" "}
                            <Link href="#" variant="button" onClick={async () => await router.push("/resetpassword")}>
                                Reset
                            </Link>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}

export default LoginForm;