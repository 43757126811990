import React, {Fragment, useEffect} from "react";
import {useSnackbar} from "notistack";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import {makeStyles} from "@material-ui/core/styles";
import {useRouter} from 'next/router';
import {connect} from "react-redux";
import {
    assessments,
    companies,
    countries,
    industries,
    member,
    permissions,
    valueDrivers,
    roles,
    scales,
    session,
    stages,
    userCompaniesRoles,
    users,
    sidebar,
} from "../../actions";
import {axiosWithCredentials} from "../../config";
import LoginForm from "./LoginForm";
import LoadingIndicator from "../../components/LoadingIndicator";
import Quotes from "../../components/Quotes";
import Typography from "@material-ui/core/Typography";
import {Hidden} from "@material-ui/core";
import Head from "next/head";

const {setAccessToken} = axiosWithCredentials;
const {cleanUpSideBar} = sidebar;
const {cleanUpMember, signIn} = member;
const {cleanUpValueDrivers} = valueDrivers;
const {cleanUpIndustries} = industries;
const {cleanUpScales} = scales;
const {cleanUpStages} = stages;
const {cleanUpCountries} = countries;
const {cleanUpCompanies} = companies;
const {cleanUpRoles} = roles;
const {cleanUpPermissions} = permissions;
const {cleanUpUsers} = users;
const {cleanUpUserCompaniesRoles} = userCompaniesRoles;
const {cleanUpAssessments} = assessments;
const {cleanUpSession, getSidebarMenus, getCompanies} = session;

const favicon = require("../../assets/favicon.png");
const logo = require("../../assets/logo.png");
const background = require("../../assets/images/background.png");

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
        minHeight: `100vh`,
        backgroundColor: "#f2f3f4",
        overflow: "hidden"
    },
    paper: {
        // margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: "100%",
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundColor: "#ffffff",
        backgroundAttachment: "fixed",
        backgroundSize: '25%',
        backgroundPosition: "top right",
        padding: 10,
    },
    logo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(10, 0, 10, 0),
        padding: theme.spacing(30, 0, 0, 0),
    },
}));

const Login = ({
                   error,
                   reason,
                   loading,
                   signIn,
                   cleanUpSideBar,
                   cleanUpMember,
                   cleanUpValueDrivers,
                   cleanUpIndustries,
                   cleanUpScales,
                   cleanUpStages,
                   cleanUpCountries,
                   cleanUpCompanies,
                   cleanUpRoles,
                   cleanUpPermissions,
                   cleanUpUsers,
                   cleanUpUserCompaniesRoles,
                   cleanUpAssessments,
                   cleanUpSession,
                   getSidebarMenus,
                   getCompanies,
               }) => {

    const classes = useStyles();

    const router = useRouter();

    const initialValues = {
        email: '',
        password: '',
    };

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const onDismiss = (key: string | number) => (
        <Fragment>
            <Button endIcon={<CloseIcon/>} onClick={() => {
                closeSnackbar(key)
            }}/>
        </Fragment>
    );

    const onSubmit = async ({email, password}) => {

        signIn({email, password}).then(async ({id, accessToken, isSuper}) => {

            setAccessToken(accessToken);

            getSidebarMenus(id);
            getCompanies(id);

            if (isSuper) {

                await router.push("/companies");
            } else {

                await router.push("/account/cockpit");
            }
        });
    };

    useEffect(() => {

        if (error) {

            enqueueSnackbar(reason, {
                variant: 'error',
                autoHideDuration: 3000,
                action: onDismiss,
            });
        }
    }, [error]);

    useEffect(() => {

        cleanUpSideBar();
        cleanUpMember();
        cleanUpValueDrivers();
        cleanUpIndustries();
        cleanUpScales();
        cleanUpStages();
        cleanUpCountries();
        cleanUpCompanies();
        cleanUpRoles();
        cleanUpPermissions();
        cleanUpUsers();
        cleanUpUserCompaniesRoles();
        cleanUpAssessments();
        cleanUpSession();
    }, []);

    return (
        <Fragment>
            <Head>
                <title>CovQ</title>
                <link rel="icon" href={favicon}/>
            </Head>
            <Grid container component="main" className={classes.root}>
                <CssBaseline/>
                <Hidden xsDown>
                    <Grid item xs={false} sm={4} md={7}>
                        <Quotes/>
                    </Grid>
                </Hidden>
                <Grid item xs={12} sm={8} md={5}>
                    <div className={classes.paper}>
                        <div className={classes.logo}>
                            <img alt="logo" src={logo} width="306px" height="121px"/>
                        </div>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <LoginForm
                            initialValues={initialValues}
                            onSubmit={onSubmit}/>
                    </div>
                    <LoadingIndicator isOpen={loading}/>
                </Grid>
            </Grid>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return ({
        error: state.member.error,
        reason: state.member.reason,
        loading: state.member.loading,
        member: state.member.member
    });
};

const mapDispatchToProps = {
    signIn,
    cleanUpSideBar,
    cleanUpMember,
    cleanUpValueDrivers,
    cleanUpIndustries,
    cleanUpScales,
    cleanUpStages,
    cleanUpCountries,
    cleanUpCompanies,
    cleanUpRoles,
    cleanUpPermissions,
    cleanUpUsers,
    cleanUpUserCompaniesRoles,
    cleanUpAssessments,
    cleanUpSession,
    getSidebarMenus,
    getCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);


